import { httpClient } from "./index";

export const getCoursesInClass = async () => {
  const courses = await httpClient.get("/courses?withProgress=true");
  if (courses) return courses.data;
}; // change later

export const getCoursesFromClass = async (classId, sort = false) => {
  const courses = await httpClient.get(
    `/courses/${classId}${sort ? "?sort=true" : ""}`,
  );
  if (courses) return courses.data;
};

export const createCourse = async (course) => {
  const formdata = new FormData();
  Object.keys(course).forEach((key) => {
    let value = course[key];
    formdata.append(key, value);
  });
  const createdCourse = await httpClient.post("/courses", formdata);
  if (createdCourse) return createdCourse.data;
};

export const getCourse = async (courseId) => {
  const course = await httpClient.get(`/courses/course/${courseId}`);
  if (course) return course.data;
};

export const deleteCourse = async (courseId) => {
  const response = await httpClient.delete(`/courses/${courseId}`);
  if (response) return response.data;
};

export const getCourseTiles = async (courseId, themeId = null) => {
  const response = await httpClient.get(
    `/courses/course/${courseId}/tiles${themeId ? "?theme=" + themeId : ""}`,
  );
  if (response) return response.data;
};

export const getCoursesWithContent = async (classGroupId) => {
  const response = await httpClient.get(`/courses/all/${classGroupId}`);
  if (response) return response.data;
};

export const updateCourse = async (courseId, course) => {
  const formdata = new FormData();
  Object.keys(course).forEach((key) => {
    let value = course[key];
    formdata.append(key, value);
  });
  const response = await httpClient.put(`/courses/${courseId}`, formdata);
  if (response) return response.data;
};

export const addTheme = async (courseId, themeIds) => {
  const response = await httpClient.post(`/courses/${courseId}/add_themes`, {
    themeIds,
  });
  if (response) return response.data;
};
