import axios from "axios";
import { getJwtToken } from "../utils";

// Determine the url from an ENV var or from the current location
// In production we expect the api to be at the same domain, but a different subdomain
let url;
if (import.meta.env.VITE_BACKEND_URL !== undefined) {
  url = import.meta.env.VITE_BACKEND_URL;
} else if (process.env.NODE_ENV === "production") {
  url = `https://api.${window.location.hostname.split(".").slice(1).join(".")}`;
}

export const backendURL = url;
axios.defaults.baseURL = backendURL;

axios.interceptors.request.use(
  function (config) {
    const userToken = getJwtToken("token");
    const accountToken = getJwtToken("account-token");
    config.headers["Authorization"] = userToken;
    config.headers["Account"] = accountToken;

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  function (config) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return config;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response && error.response.status == 401) {
      window.location.href = "/";
      localStorage.removeItem("token");
      localStorage.removeItem("mobidig-user");
      localStorage.removeItem("account-token");
      localStorage.removeItem("account");
    }
    if (error.response && error.response.status == 403) {
      window.location.href = "/courses";
    }

    return Promise.reject(error);
  },
);

export const httpClient = axios;
