import { httpClient } from "./index";

export const index = async (themeId, withProgress = false) => {
  const challenges = await httpClient.get(
    `/challenges/${themeId}?withProgress=${withProgress}`,
  );
  if (challenges) return challenges.data;
};

export const create = async (challenge) => {
  await httpClient.post(`/challenges/`, challenge);
};

export const getChallenge = async (challengeId) => {
  const challenge = await httpClient.get(
    `/challenges/get/${challengeId}?withProgress=true`,
  );
  if (challenge) return challenge.data;
};

export const deleteChallenge = async (challengeId) => {
  const response = await httpClient.delete(`challenges/${challengeId}`);
  if (response) return response.data;
};

export const uploadChallengeImage = async (imageBlob) => {
  const formdata = new FormData();
  formdata.append("image", imageBlob.blob(), imageBlob.filename());

  const response = await httpClient.post("/challenges/image", formdata);
  return response.data;
};

export const update = async (challengeId, update) => {
  const response = await httpClient.put(`/challenges/${challengeId}`, update);
  if (response) return response.data;
};
