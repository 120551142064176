export default class ApiModule {
  constructor(baseURL, path) {
    this.baseURL = baseURL;
    this.path = path;
  }

  get url() {
    return `${this.baseURL}/${this.path}`;
  }

  get jwtToken() {
    const token = localStorage.getItem("token");
    if (token) return token;

    try {
      const json = JSON.parse(localStorage.getItem("mobidig::admin::session"));
      return json.state.token;
    } catch {
      return null;
    }
  }

  async _resolve(request) {
    let response, result;
    try {
      response = await fetch(request);
      result = response.status === 204 ? true : await response.json();
    } catch (error) {
      const reason = {};
      reason[error.constructor.name] = [error.message];
      throw reason;
    }

    if (response.ok) return result;
    else throw result;
  }

  /**
   * Convert FormData to params
   * @param {FormData} formData
   *
   * @returns {URLSearchParams}
   */
  _convertParams(formData) {
    const params = new URLSearchParams();
    for (const [key, value] of formData.entries()) params.append(key, value);
    return params;
  }
}
