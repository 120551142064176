import EN from "./EN.json";
import NL from "./NL.json";
import FR from "./FR.json";
import { useOrganisationStore } from "../stores/organisation_store";

export let chosenLanguage =
  useOrganisationStore.getState().organisation?.language?.toUpperCase() ||
  import.meta.env.VITE_LANGUAGE ||
  "EN";
useOrganisationStore.subscribe(({ organisation }) => {
  chosenLanguage = organisation?.language?.toUpperCase();
});

const LANGUAGES = { EN, FR, NL };

export const translate = (label, data) => {
  let translation =
    LANGUAGES[chosenLanguage] && LANGUAGES[chosenLanguage][label];

  // Display label as fallback if not provided
  if (!translation) return label;

  if (data) {
    let newTranslation = translation;

    Object.keys(data).forEach(
      (key) =>
        (newTranslation = newTranslation.replace(
          new RegExp("{{" + key + "}}", "gm"),
          data[key],
        )),
    );
    return newTranslation;
  } else {
    return translation;
  }
};
