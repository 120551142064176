import { create } from "zustand";

export const useErrorStore = create((set) => ({
  errors: {},
  addError: (key, error) => {
    set((s) => ({ errors: { ...s.errors, [key]: error } }));
  },
  clearError: (key) => {
    set((s) => {
      let newErrors = { ...s.errors };
      delete newErrors[key];
      return { errors: newErrors };
    });
  },
  clearErrors: () => {
    set({ errors: {} });
  },
}));
