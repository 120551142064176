import { useState } from "react";
import { useEffect, useRef } from "react";

export default function Input({
  name,
  label,
  type = "text",
  required = false,
  className = "",
  disabled = false,
  hint = null,
  value = undefined,
  style = {},
  error = false,
}) {
  const field = useRef();
  const [internalValue, setInternalValue] = useState(value || "");

  // If a value is provided, we set it as the current value
  useEffect(() => {
    if (value !== undefined) field.current.value = value;
  }, [field, value]);

  return (
    <div className={["input", className].join(" ")} style={style}>
      <label htmlFor={name} className="input__label">
        {label}
      </label>
      <input
        id={name}
        type={type}
        disabled={disabled}
        required={required}
        name={name}
        ref={field}
        onChange={(e) => setInternalValue(e.target.value)}
        className="input__field"
      />
      {error && <div className="input__error">{error}</div>}
      {hint && (
        <div className="input__hint">
          {typeof hint === "function" ? hint.call(this, internalValue) : hint}
        </div>
      )}
    </div>
  );
}
