/*
 * Tiles represent both a physical item on the board and an element in our web interface
 *
 * We display the tiles in the webinterface in rows from left to right. Below we:
 * Have a map of each tile in its logical spiral and their sort order from top left to bottom right
 * Have a map of each tile in its logical spiral and their id on the physical board
 *
 * The representation below shows the web interface with the tile's number:board_id
 *   .   16:10   .   07:17  .
 * 15:04   .   06:11   .  08:18
 *   .   05:09   .   01:16  .
 * 14:05   .   17:12   .   09:19
 *   .   04:08   .   02:15   .
 * 13:06   .   03:13   .   10:20
 *   .   12:07   .   11:14   .
 */
const TILES_ORDER_MAP = {
  1: 7,
  2: 12,
  3: 14,
  4: 11,
  5: 6,
  6: 4,
  7: 2,
  8: 5,
  9: 10,
  10: 15,
  11: 17,
  12: 16,
  13: 13,
  14: 8,
  15: 3,
  16: 1,
  17: 9,
};
const TILES_ID_MAP = {
  1: 16,
  2: 15,
  3: 13,
  4: 8,
  5: 9,
  6: 11,
  7: 17,
  8: 18,
  9: 19,
  10: 20,
  11: 14,
  12: 7,
  13: 6,
  14: 5,
  15: 4,
  16: 10,
  17: 12,
};

export const MAX_LIGHTS = 17;

export const mapTile = (num) => {
  return TILES_ID_MAP[num];
};

export const getAmountofLights = (nrOfStudents) => {
  if (nrOfStudents >= 10) return 1;
  if (nrOfStudents >= 7) return 2;
  if (nrOfStudents >= 5) return 3;
  if (nrOfStudents == 2) return 9;
  if (nrOfStudents == 3) return 6;
  if (nrOfStudents == 4) return 4;
};

export const courseVisualization = Array.from({ length: 17 }, (_, index) => ({
  tile: index + 1,
  challenge: {},
  theme: {},
  lightsBurning: 0,
  studentsAnswered: [],
  sort_order: TILES_ORDER_MAP[index + 1],
}));
