import ApiModule from "./base";

export default class SessionModule extends ApiModule {
  constructor(baseURL) {
    super(baseURL, "sessions");
  }

  async create(formData) {
    const request = new Request(this.url, {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      body: this._convertParams(formData),
    });
    return await this._resolve(request);
  }
}
