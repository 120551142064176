const CUSTOM_ICONS = {
  check:
    "M2.28571 0C1.025 0 0 1.025 0 2.28571V13.7143C0 14.975 1.025 16 2.28571 16H13.7143C14.975 16 16 14.975 16 13.7143V2.28571C16 1.025 14.975 0 13.7143 0H2.28571ZM12.0357 6.32143L7.46429 10.8929C7.12857 11.2286 6.58571 11.2286 6.25357 10.8929L3.96786 8.60714C3.63214 8.27143 3.63214 7.72857 3.96786 7.39643C4.30357 7.06429 4.84643 7.06071 5.17857 7.39643L6.85714 9.075L10.8214 5.10714C11.1571 4.77143 11.7 4.77143 12.0321 5.10714C12.3643 5.44286 12.3679 5.98571 12.0321 6.31786L12.0357 6.32143Z",
  checkOutline:
    "M7.56429 10.9929C7.175 11.3821 6.53929 11.3821 6.15 10.9929L3.86429 8.70714C3.47393 8.31786 3.47393 7.68214 3.86429 7.29286C4.25357 6.90357 4.88929 6.90357 5.27857 7.29286L6.85714 8.87143L10.7214 5.00714C11.1107 4.61786 11.7464 4.61786 12.1357 5.00714C12.525 5.39643 12.525 6.03214 12.1357 6.42143L7.56429 10.9929ZM0 2.28571C0 1.02321 1.02321 0 2.28571 0H13.7143C14.975 0 16 1.02321 16 2.28571V13.7143C16 14.975 14.975 16 13.7143 16H2.28571C1.02321 16 0 14.975 0 13.7143V2.28571ZM1.71429 2.28571V13.7143C1.71429 14.0286 1.97 14.2857 2.28571 14.2857H13.7143C14.0286 14.2857 14.2857 14.0286 14.2857 13.7143V2.28571C14.2857 1.97 14.0286 1.71429 13.7143 1.71429H2.28571C1.97 1.71429 1.71429 1.97 1.71429 2.28571Z",
  chevronDown:
    "M7.46966 11.9207L1.39644 5.84742C1.10353 5.55452 1.10353 5.07964 1.39644 4.78677L2.10478 4.07842C2.39719 3.78602 2.87109 3.78545 3.16419 4.07717L8 8.89033L12.8358 4.07717C13.1289 3.78545 13.6028 3.78602 13.8952 4.07842L14.6035 4.78677C14.8964 5.07967 14.8964 5.55455 14.6035 5.84742L8.53031 11.9206C8.23744 12.2135 7.76256 12.2135 7.46966 11.9207Z",
  chevronRight:
    "M11.9211 8.53034L5.84787 14.6036C5.55497 14.8965 5.08009 14.8965 4.78722 14.6036L4.07887 13.8952C3.78647 13.6028 3.7859 13.1289 4.07762 12.8358L8.89078 8L4.07762 3.16422C3.7859 2.87113 3.78647 2.39722 4.07887 2.10481L4.78722 1.39647C5.08012 1.10356 5.555 1.10356 5.84787 1.39647L11.9211 7.46966C12.214 7.76256 12.214 8.23744 11.9211 8.53034Z",
  pencil:
    "M13.5118 2.48816L14.219 1.78105L14.219 1.78105L13.5118 2.48816ZM4.33337 14.0237V15.0237C4.59859 15.0237 4.85294 14.9183 5.04048 14.7308L4.33337 14.0237ZM2.00004 14.0237H1.00004C1.00004 14.5759 1.44775 15.0237 2.00004 15.0237V14.0237ZM2.00004 11.6429L1.29293 10.9358C1.10539 11.1234 1.00004 11.3777 1.00004 11.6429H2.00004ZM11.8619 3.19526C12.1223 2.93491 12.5444 2.93491 12.8047 3.19526L14.219 1.78105C13.1776 0.73965 11.4891 0.73965 10.4477 1.78105L11.8619 3.19526ZM12.8047 3.19526C13.0651 3.45561 13.0651 3.87772 12.8047 4.13807L14.219 5.55228C15.2604 4.51089 15.2604 2.82245 14.219 1.78105L12.8047 3.19526ZM12.8047 4.13807L3.62626 13.3165L5.04048 14.7308L14.219 5.55228L12.8047 4.13807ZM4.33337 13.0237H2.00004V15.0237H4.33337V13.0237ZM10.4477 1.78105L1.29293 10.9358L2.70714 12.35L11.8619 3.19526L10.4477 1.78105ZM1.00004 11.6429V14.0237H3.00004V11.6429H1.00004ZM9.44772 4.19526L11.8047 6.55228L13.219 5.13807L10.8619 2.78105L9.44772 4.19526Z",
  play: "M16 8C16 12.4187 12.4187 16 8 16C3.58125 16 0 12.4187 0 8C0 3.58125 3.58125 0 8 0C12.4187 0 16 3.58125 16 8ZM5.88438 4.59688C5.64688 4.72813 5.5 4.98125 5.5 5.25V10.75C5.5 11.0219 5.64688 11.2719 5.88438 11.4031C6.12188 11.5344 6.40938 11.5312 6.64375 11.3875L11.1438 8.6375C11.3656 8.5 11.5031 8.25937 11.5031 7.99687C11.5031 7.73437 11.3656 7.49375 11.1438 7.35625L6.64375 4.60625C6.4125 4.46562 6.12188 4.45937 5.88438 4.59062V4.59688Z",
  playOutline:
    "M5.88438 4.59688C6.11875 4.4625 6.40937 4.44062 6.64062 4.60938L11.1406 7.35938C11.3625 7.49688 11.5 7.7375 11.5 8C11.5 8.2625 11.3625 8.50312 11.1406 8.64062L6.64062 11.3906C6.40937 11.5312 6.11875 11.5375 5.88438 11.4031C5.64688 11.2719 5.5 11.0219 5.5 10.75V5.22188C5.5 4.97813 5.64688 4.72813 5.88438 4.59688ZM16 8C16 12.4187 12.4187 16 8 16C3.58125 16 0 12.4187 0 8C0 3.58125 3.58125 0 8 0C12.4187 0 16 3.58125 16 8ZM8 1.5C4.40937 1.5 1.5 4.40937 1.5 8C1.5 11.5906 4.40937 14.5 8 14.5C11.5906 14.5 14.5 11.5906 14.5 8C14.5 4.40937 11.5906 1.5 8 1.5Z",
  plus: "M15.3333 6.66667H9.33333V0.666667C9.33333 0.298333 9.035 0 8.66667 0H7.33333C6.965 0 6.66667 0.298333 6.66667 0.666667V6.66667H0.666667C0.298333 6.66667 0 6.965 0 7.33333V8.66667C0 9.035 0.298333 9.33333 0.666667 9.33333H6.66667V15.3333C6.66667 15.7017 6.965 16 7.33333 16H8.66667C9.035 16 9.33333 15.7017 9.33333 15.3333V9.33333H15.3333C15.7017 9.33333 16 9.035 16 8.66667V7.33333C16 6.965 15.7017 6.66667 15.3333 6.66667Z",
  question:
    "M8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16ZM5.30625 5.16563C5.55313 4.46875 6.21563 4 6.95625 4H8.77812C9.86875 4 10.75 4.88438 10.75 5.97188C10.75 6.67813 10.3719 7.33125 9.75937 7.68437L8.75 8.2625C8.74375 8.66875 8.40938 9 8 9C7.58437 9 7.25 8.66563 7.25 8.25V7.82812C7.25 7.55937 7.39375 7.3125 7.62813 7.17812L9.0125 6.38438C9.15937 6.3 9.25 6.14375 9.25 5.975C9.25 5.7125 9.0375 5.50313 8.77812 5.50313H6.95625C6.85 5.50313 6.75625 5.56875 6.72188 5.66875L6.70937 5.70625C6.57187 6.09688 6.14063 6.3 5.75313 6.1625C5.36563 6.025 5.15937 5.59375 5.29688 5.20625L5.30937 5.16875L5.30625 5.16563ZM9 11C9 11.5531 8.55313 12 8 12C7.44687 12 7 11.5531 7 11C7 10.4469 7.44687 10 8 10C8.55313 10 9 10.4469 9 11Z",
  questionOutline:
    "M8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0ZM8 14.5C4.41563 14.5 1.5 11.5841 1.5 8C1.5 4.41594 4.41563 1.5 8 1.5C11.5844 1.5 14.5 4.41594 14.5 8C14.5 11.5841 11.5844 14.5 8 14.5ZM8 10.5C7.4375 10.5 7 10.9375 7 11.5C7 12.0625 7.40938 12.5 8 12.5C8.53438 12.5 9 12.0625 9 11.5C9 10.9375 8.53438 10.5 8 10.5ZM9.03438 4H7.4375C6.21875 4 5.25 4.96875 5.25 6.1875C5.25 6.59375 5.59375 6.9375 6 6.9375C6.40625 6.9375 6.75 6.59375 6.75 6.1875C6.75 5.8125 7.03438 5.5 7.40938 5.5H9.00625C9.40938 5.5 9.75 5.8125 9.75 6.1875C9.75 6.4375 9.625 6.62813 9.40625 6.75313L7.625 7.84375C7.375 8 7.25 8.25 7.25 8.5V9C7.25 9.40625 7.59375 9.75 8 9.75C8.40625 9.75 8.75 9.40625 8.75 9V8.9375L10.1594 8.0625C10.8156 7.65625 11.2219 6.9375 11.2219 6.1875C11.25 4.96875 10.2813 4 9.03438 4Z",
  sort: "M7.29375 1.29375C7.68437 0.903122 8.31875 0.903122 8.70937 1.29375L12.7094 5.29375C12.9969 5.58125 13.0813 6.00937 12.925 6.38437C12.7688 6.75937 12.4062 7.00312 12 7.00312H4C3.59688 7.00312 3.23125 6.75937 3.075 6.38437C2.91875 6.00937 3.00625 5.58125 3.29063 5.29375L7.29063 1.29375H7.29375ZM7.29375 14.7094L3.29375 10.7094C3.00625 10.4219 2.92188 9.99375 3.07813 9.61875C3.23438 9.24375 3.59688 9 4.00313 9H12C12.4031 9 12.7688 9.24375 12.925 9.61875C13.0813 9.99375 12.9938 10.4219 12.7094 10.7094L8.70937 14.7094C8.31875 15.1 7.68437 15.1 7.29375 14.7094V14.7094Z",
  trash:
    "M4.25 14.5C3.8375 14.5 3.4845 14.3533 3.191 14.0598C2.897 13.7658 2.75 13.4125 2.75 13V3.25H2V1.75H5.75V1H10.25V1.75H14V3.25H13.25V13C13.25 13.4125 13.1033 13.7658 12.8098 14.0598C12.5158 14.3533 12.1625 14.5 11.75 14.5H4.25ZM11.75 3.25H4.25V13H11.75V3.25ZM5.75 11.5H7.25V4.75H5.75V11.5ZM8.75 11.5H10.25V4.75H8.75V11.5Z",
  xmark:
    "M8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16ZM5.46875 5.46875C5.7625 5.175 6.2375 5.175 6.52812 5.46875L7.99687 6.9375L9.46562 5.46875C9.75937 5.175 10.2344 5.175 10.525 5.46875C10.8156 5.7625 10.8187 6.2375 10.525 6.52812L9.05625 7.99687L10.525 9.46562C10.8187 9.75937 10.8187 10.2344 10.525 10.525C10.2312 10.8156 9.75625 10.8187 9.46562 10.525L7.99687 9.05625L6.52812 10.525C6.23437 10.8187 5.75938 10.8187 5.46875 10.525C5.17812 10.2312 5.175 9.75625 5.46875 9.46562L6.9375 7.99687L5.46875 6.52812C5.175 6.23437 5.175 5.75938 5.46875 5.46875V5.46875Z",
  xmarkOutline:
    "M5.46875 5.46875C5.7625 5.17812 6.2375 5.17812 6.50313 5.46875L7.97188 6.94063L9.46875 5.46875C9.7625 5.17812 10.2375 5.17812 10.5031 5.46875C10.8219 5.7625 10.8219 6.2375 10.5031 6.50313L9.05937 7.97188L10.5031 9.46875C10.8219 9.7625 10.8219 10.2375 10.5031 10.5031C10.2375 10.8219 9.7625 10.8219 9.46875 10.5031L7.97188 9.05937L6.50313 10.5031C6.2375 10.8219 5.7625 10.8219 5.46875 10.5031C5.17812 10.2375 5.17812 9.7625 5.46875 9.46875L6.94063 7.97188L5.46875 6.50313C5.17812 6.2375 5.17812 5.7625 5.46875 5.46875ZM16 8C16 12.4187 12.4187 16 8 16C3.58125 16 0 12.4187 0 8C0 3.58125 3.58125 0 8 0C12.4187 0 16 3.58125 16 8ZM8 1.5C4.40937 1.5 1.5 4.40937 1.5 8C1.5 11.5906 4.40937 14.5 8 14.5C11.5906 14.5 14.5 11.5906 14.5 8C14.5 4.40937 11.5906 1.5 8 1.5Z",
};

const { NOUN_ICONS } = import("../utils/icons");

export default function Icon({ name, className = "", style = {} }) {
  const collection = name.split("-")[0];
  const iconName = name.replace(`${collection}-`, "");

  if (iconName === "undefined") return;

  switch (collection) {
    case "mdi":
      return (
        <span
          className={`icon icon--mdi material-symbols-outlined ${className}`}
          style={style}
        >
          {iconName}
        </span>
      );
    case "noun":
      return (
        <span
          className={`icon icon--noun ${className}`}
          style={style}
          dangerouslySetInnerHTML={{ __html: NOUN_ICONS[iconName] }}
        ></span>
      );
    case "custom":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          className={`icon icon--custom ${className}`}
          fill="currentColor"
          style={style}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={CUSTOM_ICONS[iconName]} />
        </svg>
      );
  }
}
