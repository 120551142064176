import { httpClient } from ".";

export const startQuiz = async (classgroup, quizId) => {
  await httpClient.post("/quiz", { classgroup, quizId });
};

export const viewingChallenge = async (courseId, themeId, challengeId) => {
  await httpClient.post("/challenges/viewing", {
    courseId,
    themeId,
    challengeId,
  });
};
