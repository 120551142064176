import ApiModule from "./base";

export default class OrganisationModule extends ApiModule {
  constructor(baseURL) {
    super(baseURL, "organisations");
  }

  async index() {
    const request = new Request(this.url, {
      method: "GET",
      headers: {
        Authorization: this.jwtToken,
        "content-type": "application/json",
      },
    });
    return await this._resolve(request);
  }

  async create(formData) {
    const request = new Request(this.url, {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      body: this._convertParams(formData),
    });
    return await this._resolve(request);
  }

  async read(id) {
    const request = new Request(`${this.url}/${id}`, {
      method: "GET",
      headers: {
        Authorization: this.jwtToken,
        Accept: "application/json",
      },
    });
    return await this._resolve(request);
  }

  async update(id, formData) {
    const request = new Request(`${this.url}/${id}`, {
      method: "PATCH",
      headers: {
        Authorization: this.jwtToken,
        Accept: "application/json",
      },
      body: formData,
    });
    return await this._resolve(request);
  }

  async confirm(id, formData) {
    const request = new Request(`${this.url}/${id}/confirm`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    });
    return await this._resolve(request);
  }
}
