export const USER_ROLES = {
  STUDENT: "student",
  TEACHER: "teacher",
  SUPER_ADMIN: "super_admin",
};

export const PROGRESS_STATUS = {
  DIFFICULTIES: "difficulties",
  DONE: "done",
  STARTED: "started",
};

export const STANDARD_QUIZZES = [
  {
    id: "DEFAULT",
    text: "default_quiz",
  },
  {
    id: "EVALUATION",
    text: "default_evaluation",
  },
];

export const ALL_THEMES = {
  name: "All Themes",
  _id: "all",
};
