import { createAPIClient } from "@mobidig/api-client";

let url;
if (import.meta.env.VITE_BACKEND_URL !== undefined) {
  url = import.meta.env.VITE_BACKEND_URL;
} else if (process.env.NODE_ENV === "production") {
  url = `https://api.${window.location.hostname.split(".").slice(1).join(".")}`;
}

export const backendURL = url;
export const api = createAPIClient(backendURL);
