import { httpClient } from "./index";

export const login = async (user, role) => {
  const response = await httpClient.post(`/auth/login?role=${role}`, user);
  if (response && response.data.accessToken) {
    localStorage.setItem("token", response.data.accessToken);
  }

  if (response.data.accountToken) {
    localStorage.setItem("account-token", response.data.accountToken);
    localStorage.setItem("account", JSON.stringify(response.data.account));
  }
  return response.data;
};

export const GetStudents = async (classGroupId) => {
  const students = await httpClient.get(`/auth/students/${classGroupId}`);
  if (students) return students.data;
};

export const getStudentsInOrganisation = async () => {
  const students = await httpClient.get("/auth/allstudents");
  if (students) return students.data;
};

export const getTeachersInOrganisation = async () => {
  const teachers = await httpClient.get("/auth/allteachers");
  if (teachers) return teachers.data;
};

export const registerStudent = async (student) => {
  const newStudent = await httpClient.post(
    `/auth/register?role=student`,
    student,
  );
  if (newStudent) return newStudent.data;
};

export const registerTeacher = async (teacher) => {
  const newTeacher = await httpClient.post(
    "/auth/register?role=teacher",
    teacher,
  );
  if (newTeacher) return newTeacher.data;
};

export const deleteUser = async (id) => {
  const deletedUser = await httpClient.delete(`/auth/${id}`);
  if (deletedUser) return deletedUser.data;
};

export const editUser = async (id, update) => {
  const editedUser = await httpClient.put(`/auth/${id}`, update);
  if (editedUser) return editedUser.data;
};

export const getAccountUsers = async (id) => {
  const users = await httpClient.get(`/auth/users/${id}`);
  if (users) return users.data;
};
