import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useErrorStore } from "../stores/error_store";
import { useUserStore } from "../stores/user_store";
import { USER_ROLES } from "../utils/constant";

export default function ProtectedRoutes({ requiredRole }) {
  const user = useUserStore((s) => s.user);
  const addError = useErrorStore((s) => s.addError);
  const authorized = user?.role === requiredRole;
  const navigate = useNavigate();

  useEffect(() => {
    if (authorized) return;

    addError("login", { required: { role: requiredRole } });
    navigate(requiredRole === USER_ROLES.STUDENT ? `/login` : `/teacher/login`);
  }, []);

  return authorized ? <Outlet /> : null;
}
