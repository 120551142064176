import { create } from "zustand";
import { api } from "../api";

export const useOrganisationStore = create((set, get) => ({
  organisation: null,
  read: async () => {
    const host = window.location.host;
    const subdomain = host.split(".")[0];
    const organisation = await api.organisations.read(subdomain);
    set({ organisation: organisation });
  },
  update: async (data) => {
    const newOrganisation = await api.organisations.update(
      get().organisation.id,
      data,
    );
    set({ organisation: newOrganisation });
    return newOrganisation;
  },
}));
