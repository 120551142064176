import { httpClient } from "./index";

export const getThemesFromCourse = async (courseId) => {
  const response = await httpClient.get(
    `/themes/course/${courseId}?withProgress=true`,
  );
  if (response) return response.data;
};

export const getThemesWithStudentProgress = async (courseId) => {
  const response = await httpClient.get(
    `/themes/course/studentinfo/${courseId}`,
  );
  if (response) return response.data;
};

export const index = async ({ content = false }) => {
  const response = await httpClient.get(
    `/themes${content ? "?content=true" : ""}&sort=true`,
  );
  if (response) return response.data;
};

export const create = async (theme, { contentMode = false }) => {
  const response = await httpClient.post(
    `/themes${contentMode ? "?content=true" : ""}`,
    theme,
  );
  if (response) return response.data;
};

export const read = async (id) => {
  const response = await httpClient.get(`/themes/${id}`);
  if (response) return response.data;
};

export const update = async (id, newTheme) => {
  const response = await httpClient.put(`/themes/${id}`, newTheme);
  if (response) return response.data;
};

export const destroy = async (themeId) => {
  const response = await httpClient.delete(`/themes/${themeId}`);
  if (response) return response.data;
};
