import GroupModule from "./group";
import OrganisationModule from "./organisation";
import PasswordModule from "./password";
import SessionModule from "./session";

export function createAPIClient(baseURL) {
  return {
    groups: new GroupModule(baseURL),
    organisations: new OrganisationModule(baseURL),
    passwords: new PasswordModule(baseURL),
    sessions: new SessionModule(baseURL),
  };
}
