// Re-export separate files
export * from "./constant";
export * from "./illustrations";
export * from "./tiles";

export const getJwtToken = (model) => localStorage.getItem(model);

import logoLIDK from "../assets/logos/logo_lidk.png";
import logoHOBO from "../assets/logos/logo_hobo.png";
import logoLMDP from "../assets/logos/logo_lmdp.png";
import logoDigidak from "../assets/logos/logo_digidak.png";
import logoIf3Namur from "../assets/logos/logo_if3namur.png";

export const getOrganisationLogo = () => {
  const host = window.location.host;
  const subdomain = host.split(".")[0];

  const logos = {
    lidk: logoLIDK,
    lmdp: logoLMDP,
    hobo: logoHOBO,
    digidak: logoDigidak,
    if3namur: logoIf3Namur,
  };
  return logos[subdomain];
};

export const getColorByStatus = (status) => {
  if (status === "started") return "Blue";
  if (status === "difficulties") return "Red";
  if (status === "done") return "Green";
};

export const convertSeconds = (secs) => {
  let minutes = Math.floor(secs / 60);
  let seconds = secs % 60;
  if (minutes.toString().length < 2) minutes = "0" + minutes;
  if (seconds.toString().length < 2) seconds = "0" + seconds;
  return `${minutes}:${seconds}`;
};

export const necessities = [
  {
    label: "necessity_internet",
    value: "internet",
    icon: "mdi-language",
  },
  {
    label: "necessity_wifi",
    value: "wifi",
    icon: "mdi-wifi",
  },
  {
    label: "necessity_word",
    value: "word",
    icon: "noun-wordFile",
  },
  {
    label: "necessity_powerpoint",
    value: "powerpoint",
    icon: "noun-pptFile",
  },
  {
    label: "necessity_google_docs",
    value: "google_docs",
    icon: "noun-drive",
  },
  {
    label: "necessity_cloud",
    value: "cloud",
    icon: "mdi-cloud_upload",
  },
  {
    label: "google_chrome",
    value: "chrome",
    icon: "noun-chromeBrowser",
  },
  {
    label: "necessity_google_drive",
    value: "google_drive",
    icon: "noun-googleDrive",
  },
  {
    label: "necessity_computer",
    value: "computer",
    icon: "mdi-computer",
  },
  {
    label: "necessity_laptop",
    value: "laptop",
    icon: "mdi-computer",
  },
  {
    label: "necessity_computer_mouse",
    value: "computer_mouse",
    icon: "mdi-mouse",
  },
  {
    label: "necessity_smartphone",
    value: "smartphone",
    icon: "mdi-phone_iphone",
  },
  {
    label: "necessity_keyboard",
    value: "keyboard",
    icon: "noun-keyboard",
  },
  {
    label: "necessity_email",
    value: "email",
    icon: "mdi-mail",
  },
  {
    label: "necessity_whatsapp",
    value: "whatsapp",
    icon: "noun-whatsapp",
  },
  {
    label: "necessity_bank_account",
    value: "bank_account",
    icon: "mdi-credit_card",
  },
  {
    label: "necessity_card_reader",
    value: "bank_account",
    icon: "noun-cardReader",
  },
];
