import { useOrganisationStore } from "../stores/organisation_store";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function OrganisationLoader({ children }) {
  const { organisation, read } = useOrganisationStore((state) => ({
    organisation: state.organisation,
    read: state.read,
  }));

  useEffect(() => {
    read();
  }, []);

  return organisation ? (
    <>
      <Helmet>
        <html lang={organisation.language?.toLowerCase()}></html>
        <body data-branding-colors={organisation.colorSet}></body>
      </Helmet>
      {children}
    </>
  ) : (
    <div className="loading-screen">
      <p className="loading-screen__message">Loading mobidig application</p>
      <svg
        className="loading-screen__spinner"
        viewBox="0 0 16 16"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M3.19999 1.60001C3.64182 1.60001 3.99999 1.95818 3.99999 2.40001V4.08101C5.01607 3.04391 6.43241 2.40001 7.99999 2.40001C10.4395 2.40001 12.5128 3.95946 13.2811 6.13341C13.4284 6.54998 13.21 7.00704 12.7935 7.15428C12.3769 7.30152 11.9198 7.08318 11.7726 6.6666C11.2231 5.11193 9.74037 4.00001 7.99999 4.00001C6.69205 4.00001 5.52962 4.62802 4.79948 5.60001H7.19999C7.64182 5.60001 7.99999 5.95818 7.99999 6.40001C7.99999 6.84183 7.64182 7.20001 7.19999 7.20001H3.19999C2.75817 7.20001 2.39999 6.84183 2.39999 6.40001V2.40001C2.39999 1.95818 2.75817 1.60001 3.19999 1.60001ZM3.20653 8.84573C3.6231 8.6985 4.08016 8.91684 4.2274 9.33341C4.77689 10.8881 6.25962 12 7.99999 12C9.30794 12 10.4704 11.372 11.2005 10.4L8.79999 10.4C8.35817 10.4 7.99999 10.0418 7.99999 9.60001C7.99999 9.15818 8.35817 8.80001 8.79999 8.80001H12.8C13.0122 8.8 13.2156 8.88429 13.3657 9.03432C13.5157 9.18435 13.6 9.38783 13.6 9.60001V13.6C13.6 14.0418 13.2418 14.4 12.8 14.4C12.3582 14.4 12 14.0418 12 13.6V11.919C10.9839 12.9561 9.56758 13.6 7.99999 13.6C5.56047 13.6 3.48723 12.0405 2.71885 9.8666C2.57161 9.45003 2.78995 8.99297 3.20653 8.84573Z" />
      </svg>
    </div>
  );
}
