import { httpClient } from "./index";

export const getQuizzes = async () => {
  const response = await httpClient.get("/quiz");
  if (response) return response.data;
};

export const getQuiz = async (quizId) => {
  const response = await httpClient.get(`/quiz/${quizId}`);
  if (response) return response.data;
};

export const createQuiz = async (quiz) => {
  const response = await httpClient.post("/quiz/create", quiz);
  if (response) return response.data;
};

export const deleteQuiz = async (quizId) => {
  const response = await httpClient.delete(`/quiz/${quizId}`);
  if (response) return response.data;
};

export const answerQuiz = async (quizId, teacherId, answer, index) => {
  const response = await httpClient.post(`/quiz/answer`, {
    quizId,
    teacherId,
    answer,
    index,
  });
  if (response) return response.data;
};

export const editQuiz = async (quizId, quiz) => {
  const response = await httpClient.put(`/quiz/${quizId}`, quiz);
  if (response) return response.data;
};
