import ApiModule from "./base";

export default class PasswordModule extends ApiModule {
  constructor(baseURL) {
    super(baseURL, "passwords");
  }

  async reset(email) {
    const request = new Request(this.url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    return await this._resolve(request);
  }

  async update(data) {
    const request = new Request(this.url, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await this._resolve(request);
  }
}
