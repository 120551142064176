import { httpClient } from "./index";

export const create = async (params) =>
  await httpClient.post("/progress", params);

export const getAllUserProgress = async () => {
  const progress = await httpClient.get("/progress");
  if (progress) return progress.data;
};

export const getThemeProgress = async (themeId) => {
  const progress = await httpClient.get(`/progress/theme/${themeId}`);
  if (progress) return progress.data;
};

export const getChallengeProgress = async (challengeId) => {
  const progress = await httpClient.get(`/progress/challenge/${challengeId}`);
  if (progress) return progress.data;
};

export const getProgressesFromCourse = async (courseId) => {
  const response = await httpClient.get(`/progress/course/${courseId}`);
  if (response) return response.data;
};
